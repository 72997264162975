.files {
    @mixin flexVList var(--list-gap);
    align-items: flex-end;
    width: 100%;

    .error {
        color: var(--c-red);
        font-weight: var(--f-normal);
        white-space: break-spaces;
        word-break: break-word;
    }

    .upload {
        position: relative;
        display: flex;
        gap: var(--items-gap);
        align-items: center;
        cursor: pointer;

        .loader {
            top: 0;
            left: 0;
            display: flex;
            background: transparent;
            color: var(--c-blue2);
            visibility: hidden;

            svg {
                width: 2ch;
                height: 2ch;
            }
        }

        &.hidden > svg,
        &.hidden .btn {
            visibility: hidden;
            pointer-events: none;
        }

        &.hidden .loader {
            visibility: visible;
        }

        svg {
            flex-shrink: 0;
        }

        .btn {
            display: inline-flex;
            align-items: center;
            padding: 0;
            border: 0;
            background: transparent;
            outline: none;
            font-weight: var(--f-normal);
        }
    }

    .restrictions {
        display: flex;
        gap: 1ch;
    }

    .file {
        display: flex;
        flex-direction: row;
        gap: 2ch;
        align-items: center;
        font-weight: var(--f-normal);
        word-break: break-word;

        .deleteIcon {
            flex-shrink: 0;
            width: 2ch;
            height: 2ch;
            cursor: pointer;
        }
    }
}
