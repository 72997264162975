.audioRecorder {
    position: fixed;
    top: 3em;
    right: 3em;
    z-index: 1000000;
    background: transparent;
}

.controls {
    @mixin gridCenter;
    width: 100%;
    height: 100%;
}
