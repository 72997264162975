.testResultsWrapper {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    & p {
        margin: 0;
    }
}

.testResults {
    @mixin resultShadow;
    width: 50vw;
    max-width: 802px;
    margin: 20px 0;
    border-radius: 25px;
    background: var(--c-white);

    @media (--medium-until) {
        width: 90vw;
    }
}

.testResults__header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    padding: 0 40px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    color: var(--c-white);
    font-weight: var(--f-bold);
    font-size: 14px;
    line-height: 17px;
    text-align: center;

    p {
        display: inline;
    }
}

.testResults__body {
    display: flex;
    flex-flow: column;
    margin: 65px 0;
    padding: 0 40px;
}

.testResults__bodyTitle {
    color: var(--c-grey3);
    font-weight: var(--f-bold);
    font-size: 36px;
    line-height: 44px;
    text-align: center;

    @media (--medium-until) {
        font-size: 24px;
    }

    p {
        display: inline;
    }
}

.testResults__bodyDescription {
    margin-top: 15px;
    margin-bottom: 25px;
    color: var(--c-grey3);
    font-weight: var(--f-normal);
    font-size: 18px;
    line-height: 22px;
    text-align: center;
}

.testResults__bodyResults {
    display: flex;
    justify-content: center;

    & div:first-child {
        margin-right: 10px;
    }
}

.testResultsWrapper__questions {
    display: flex;
    flex-flow: column;
    width: 100%;
}

.testResultsText {
    margin: 10px 0;
}
