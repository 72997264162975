.leftRight {
    width: 100%;

    .labels {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .label {
            width: calc(50% - 12px);
            font-weight: var(--f-normal);
            white-space: break-spaces;
            word-break: break-word;

            &:last-child {
                text-align: right;
            }
        }
    }

    .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
