.list {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (--medium-until) {
        grid-template-columns: 1fr !important;

        &.listWithColumns {
            columns: 1 !important;
        }
    }

    .answer:not(:last-child) {
        margin-bottom: 2px;
    }

    .answer {
        @mixin flexVCenter;
        @mixin flexHList var(--items-gap);
        width: 100%;
        margin-bottom: 1ch !important;
        padding: 0 1ch;
        cursor: pointer;

        @media (--component-break1) {
            flex-flow: column;
            align-items: flex-start;
            height: unset !important;
        }

        &.answerInvalid {
            border-radius: var(--b-border-radius);
            background: var(--c-red_a_1a);
        }

        .checkbox {
            @mixin flexVCenter;
            @mixin flexHList var(--items-gap);
            min-width: 2ch;
            word-break: break-word;
        }

        .text {
            @media (--medium-until) {
                width: 100%;
            }

            @media (--medium) {
                flex-grow: 1;
            }
        }
    }
}

.results {
    display: flex;
    gap: 1ch;
    justify-content: flex-start;
    margin: 20px 0;
}
