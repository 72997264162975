.title {
    position: absolute;
    line-height: 1.5em;
    text-align: center;
    word-break: break-word;

    @media (--medium-until) {
        position: static !important;
        width: auto !important;
        height: auto !important;
    }
}

.container {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    margin: initial;

    @media (--medium-until) {
        position: static;
        z-index: -1;
        order: 2;
    }
}

.titleInHead {
    position: absolute;
    text-align: center;
    word-break: break-word;

    @media (--medium-until) {
        font-size: clamp(18px, calc(0.5em + 2vw), 1em);

        &:empty {
            min-height: var(--mobile-height);
        }
    }
}
