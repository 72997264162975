.root {
    @mixin flexVList 0;
}

.rank {
    @mixin flexVList 0;
    width: 100%;

    p {
        margin: 0;
        word-break: break-all;
    }
}

.variant {
    @mixin flexHList var(--items-gap);
    @mixin flexVCenter;
    width: 100%;
    max-width: max-content;
    height: auto;
    touch-action: none;

    .order {
        white-space: nowrap;
    }

    .text {
        overflow: hidden;
        width: 100%;
        user-select: none;
    }
}

.current {
    visibility: hidden;
}

.draggable {
    @mixin popupShadow;
    width: fit-content;
    padding: 0.5em;
    border-radius: var(--b-border-radius);
    background-color: var(--c-white);
    touch-action: none;
}
