.distribute {
    @mixin vItems;
    width: 100%;

    .text {
        @mixin flexHList 0.5ch;
        font-weight: var(--f-normal);

        .score {
            color: var(--c-blue);
        }
    }

    p {
        margin: 0;
    }

    .requiredText {
        color: var(--c-red);
        font-weight: var(--f-normal);
    }

    .answers {
        display: flex;
        flex-direction: column;

        @media (--medium) {
            display: grid;
            grid-template-rows: 1fr;
            grid-template-columns:
                clamp(10ch, var(--scale-label-width, 15%), 25ch)
                clamp(250px, calc(85% - 3.5ch), 600px);
            align-items: center;
        }

        .answer {
            align-items: center;

            @media (--medium) {
                display: contents;
            }

            .label,
            .slider {
                @media (--medium) {
                    margin-bottom: 0.5em;
                }
            }

            .label {
                font-weight: var(--f-normal);
                overflow-wrap: anywhere;

                @media (--medium-until) {
                    margin-bottom: 1ch;
                }
            }

            .slider .field .control {
                border-radius: var(--border-width);
            }
        }
    }
}
