.list {
    display: flex;
    flex-direction: column;

    @media (--medium) {
        display: grid;
        grid-template-columns: repeat(2, auto);
        align-items: center;
        column-gap: 2ch;
    }

    @media (--medium-until) {
        width: 100%;
    }

    p {
        margin: 0;
    }

    .answer_invalid {
        border-radius: var(--b-border-radius);
        background: var(--c-red_a_1a);
    }

    .answer {
        display: flex;

        @media (--medium) {
            display: contents;
        }

        @media (--medium-until) {
            flex-flow: column;
            flex-wrap: wrap;
            align-items: flex-start;
            width: 100%;
            height: unset !important;
        }

        .label {
            flex: 0 1 auto;
            font-weight: var(--f-normal);

            @media (--medium-until) {
                max-width: max-content;
                margin-right: 2ch;
                margin-bottom: 5px;
                padding: 1ch;
            }
        }
    }
}
