.list {
    display: flex;
    flex-direction: column;
    gap: 2ch;
    width: 100%;

    @media (--medium) {
        display: grid;
        align-items: center;
    }

    .answer {
        display: flex;
        gap: 1ch;
        align-items: center;

        @media (--medium) {
            display: contents;
        }

        @media (--medium-until) {
            flex-flow: column;
        }

        & > div {
            width: 100%;
        }

        .label {
            display: flex;
            flex-shrink: 1;
            font-weight: var(--f-normal);

            @media (--medium-until) {
                align-self: flex-start;
            }

            p {
                margin: 0;
            }
        }
    }
}

.date {
    @media (--medium) {
        display: flex;
        align-items: center;
    }

    &:not(:last-child) {
        @media (--component-break1) {
            margin-bottom: 15px;
        }
    }

    .field {
        @media (--medium) {
            flex-grow: 1;
            max-width: calc((11em + 11ch) / 2);
        }

        :global {
            .react-datepicker__day-names,
            .react-datepicker__week {
                display: flex;
            }

            .react-datepicker__day,
            .react-datepicker__day-name {
                min-width: 50px !important;
                text-align: center;

                @media (--medium-until) {
                    width: 35px;
                    min-width: 35px !important;
                }
            }

            .react-datepicker__day-names .react-datepicker__day-name {
                text-align: center;
            }
        }
    }
}

.time {
    display: flex;
    flex-grow: 1;
    gap: 1ch;
    align-items: center;

    .field {
        flex-grow: 1;
        width: calc((8em + 8ch) / 2);
    }

    .divider {
        font-weight: var(--f-bold);
    }
}

.inputText {
    text-align: center;
}
