.tools {
    @mixin flexCenter;
    position: fixed;
    bottom: var(--p-inner);
    left: var(--p-inner);
    z-index: 1000;
    height: fit-content;
    font-size: var(--t-level3);
}

.gear {
    z-index: 2;
    width: clamp(32px, 3vw, 64px);
    height: auto;
    border-radius: 50%;
    color: var(--c-yellow);
    cursor: pointer;

    &.online {
        color: var(--c-green);
    }
}

.content {
    @mixin widgetBorder;
    position: absolute;
    bottom: var(--p-inner);
    left: var(--p-inner);
    z-index: 1;
    display: grid;
    flex-wrap: wrap;
    gap: 1.5ch;
    justify-content: space-around;
    width: max-content;
    padding: 3em;
    border-radius: var(--b-border-radius);
    background-color: var(--c-white);

    @media (--medium-until) {
        width: 90vw;
        padding: var(--p-inner);
    }

    label {
        margin-bottom: 1.5ch;
        color: var(--c-blue);
        cursor: pointer;

        &::after {
            content: '';
            display: inline-block;
            width: 1ch;
            height: 1ch;
            margin-left: 0.75ch;
            border: 0.125rem solid;
            border-bottom: 0;
            border-left: 0;
            border-radius: 1px;
            transition: all 0.35s;
            transform: rotate(135deg);
            transform-origin: 0.5ch 0.3ch;
            will-change: transform;
        }
    }

    .statToggle {
        position: absolute;
        z-index: -1;
        opacity: 0;

        & ~ ul {
            overflow: hidden;
            max-height: 0;
            transition: all 0.35s;
            will-change: max-height;
        }

        &:checked {
            & ~ ul {
                max-height: 100vh;
                padding: var(--list-gap) 0;
            }
        }

        &:checked + label::after {
            transform: rotate(315deg);
        }
    }
}

.close {
    top: 0.75ch;
    right: 0.75ch;
    width: clamp(16px, var(--t-level1), var(--t-level1));
    height: clamp(16px, var(--t-level1), var(--t-level1));
}

.installPopup,
.popupBg {
    @mixin flexCenter;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.popupBg {
    background-color: var(--c-white);
    opacity: 0.85;
}

.installPopup {
    .installButton {
        width: clamp(150px, 100px + 10vw, 300px) !important;
    }
}
