.testText {
    @mixin flexVList 0;
    width: 100%;
}

.fullWidth {
    width: 100%;
}

.checked {
    @mixin flexVCenter;
    @mixin flexHList var(--items-gap);
}
